import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../../layouts/mdx';
export const _frontmatter = {
  "articles": "verify",
  "title": "What is a MitID service provider alias?",
  "category": "Danish MitID"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When setting up your MitID service provider, you are asked to pick an `}<inlineCode parentName="p">{`alias`}</inlineCode>{`.`}</p>
    <p>{`The MitID service provider alias will be displayed in the login box on web, and in the app when they approve the login/transaction.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "502px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "146.875%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAdCAYAAACqhkzFAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSklEQVRIx+1V2bKiMBTk/z9jPmTeBpf7MKWAIu4IeB0W2bcEeipRvF5lFH2eVHWdVKKd0zl9gkAIwaNR1zWqqroDpRXbBdDEGiUhENgfijxHEASI4whxFCEIQ7iuA8d1UJTl/SHnuLYpdh7F1CI4BBUnF9jG0fOgKAoM04BpGjBME6o6habNkCTpJdNrsNFbFPg5yfHjVwLVIidCSimeDSbxWnpdn2IjtcmZXYPANtg9hmGIOI5RliWSLL/LpvUgvn8Vq7Nk3/chjcdYLpdwvSO25gEs81uZj9AoEU4Vo1/VIwQVpTzTV8BUXgjZQnNPz2T+y1oNxyXDW5JXpLLYqOQ+ZMXY7XYwDIODzU3T7Az2+yzLODEvimGYGA4/IEkyRqMRj/1+H6IoQhR7Z4jo9XrfwNYGgwGGwyFs2+bZckLXsaHIY8zUKdTphEORJchnKIqEiSJBku4hyzKPzCmcsK4qpEUNP6EIU4oopYgzijSvkBUnsHW233aHjTsutmHtwnpytC2xcwg+5gU0i2Bmltj8IZhbJZ8v9qRTRwn0zN7VHp2M/Y5Nbg9qJeySRZvnHxK+M/4Tvlbltmp/I+zyCXiU1R0ha728KJGkGaI4QZykp3mSIs1yjjwv+LofRny9zWJXrQd82h7W+h7aagdZXUJd6JDUBVb6HhNtDd08YL7W8VuZY7Ex0HRX3dp6b0h+2Hr8gU1S2O4RtuvzbwM7ZH9w4B6Dr1eleVme9TKbeEcf2krHSrdQEsoJlxsDq631kqU6Se7S49cZ/gW5mtaAUELa4gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Service Provider ALIAS on web",
          "title": "Service Provider ALIAS on web",
          "src": "/static/16b1958604e4d298f2a6d50f37a15178/eea79/service-provider-alias-web.png",
          "srcSet": ["/static/16b1958604e4d298f2a6d50f37a15178/8514f/service-provider-alias-web.png 192w", "/static/16b1958604e4d298f2a6d50f37a15178/804b2/service-provider-alias-web.png 384w", "/static/16b1958604e4d298f2a6d50f37a15178/eea79/service-provider-alias-web.png 502w"],
          "sizes": "(max-width: 502px) 100vw, 502px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      